import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row,
} from "reactstrap"
import footerLogo from "assets/images/footer-logo.svg"
import { post } from "api/axios"
import endpoints from "api/endpoints"
import captcha from "assets/images/captcha.png"
import { Bounce, toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const Footer = () => {
  const [email, setEmail] = useState("")
  const footerItems = [
    {
      title: "Quick Links",
      items: [
        { title: "Home", path: "/" },
        { title: "About", path: "/about" },
        { title: "Video Vault", path: "/videovaults" },
        { title: "The Dockie Awards", path: "/thedockies" },
      ],
    },
    {
      title: "Policies",
      items: [
        { title: "Terms & Conditions", path: "/termcondition" },
        { title: "Privacy Policy", path: "/privacypolicy" },
      ],
    },
  ]

  const mailHandler = async () => {
    const resp = await post(endpoints.mail, { email })

    if (resp.status === 201) {
      toast.success("You have successfully subscribed the Newsletter", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
      setEmail("")
    } else {
      toast.error("You have already subscribed the Newsletter ", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
    }
  }
  const navigate = useNavigate()

  const handleClick = () => {
    window.scrollTo(0, 0)
    navigate("/")
  }
  return (
    <footer className="landing-bg footer mt-auto d-lg-block d-none ">
      <Container fluid className="pt-5 pb-2">
        <Row className="align-items-center justify-content-center pb-4">
          <Col xs="12" md="5" className="mb-3">
            <div className="d-flex flex-column justify-content-center">
              <Link to="/" onClick={handleClick}>
                <img
                  src={footerLogo}
                  alt="footer-logo"
                  className="img-fluid"
                  height="80"
                  width="160"
                />
              </Link>
              <p className="text-white font-size-14r py-2 m-0 text-uppercase">
                Dad Joke Headquarters
              </p>
            </div>
          </Col>
          <Col xs="12" md="6">
            <Row>
              {footerItems.map((el, index) => {
                return (
                  <Col xs="12" md="4" className="mb-3" key={index}>
                    <h5 className="text-white text-uppercase">{el.title}</h5>
                    <ul className="nav flex-column pt-3 font-size-14">
                      {el.items.map((subEl, subIndex) => {
                        return (
                          <li className="nav-item mb-2" key={subIndex}>
                            <Link
                              to={`${subEl.path}`}
                              className="secondary-white"
                              onClick={handleClick}
                            >
                              {subEl.title}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </Col>
                )
              })}
              <Col xs="12" md="4" className="mb-3 d-flex flex-column gap-3">
                <h5 className="text-white">Mailing List</h5>
                <Form>
                  <InputGroup className="d-flex align-items-center bg-white rounded-2">
                    <Input
                      type="email"
                      className="form-control border-0 bg-transparent"
                      placeholder="Email address"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    <Button
                      color="link"
                      className="p-0"
                      onClick={e => {
                        mailHandler()
                      }}
                    >
                      <i
                        style={{ rotate: "320deg" }}
                        className="fa-solid fa-paper-plane-top text-dark me-2"
                      ></i>
                    </Button>
                  </InputGroup>
                </Form>
                <div className="d-flex gap-3 align-items-center">
                  <img src={captcha} alt="" height={"32px"} width={"32px"} />
                  <span className="secondary-white">Captcha by Google</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <div className="d-flex align-items-center justify-content-center py-2 landing-copyrights text-uppercase">
        <span className="text-center text-white font-size-14">
          © 2024 Dock Tok, Inc. All Rights Reserved.
        </span>
      </div>
    </footer>
  )
}

export default Footer
