import React, { useState, useEffect } from "react"
import personPlaceholder from "../../../assets/images/personPlaceholder.png"
import { Link } from "react-router-dom"
import { Col, Container, Row, Placeholder } from "reactstrap"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import Placeholders from "../../Home/components/Placeholders"
import ShuffleText from "components/animation/ShullfeText/ShullfeText"

const WinnerFromLastMonth = ({ loadmore = false }) => {
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)

  const tabsMenu = [
    "All",
    "Parent / Child Combo",
    "Best Background",
    "Most Funny",
    "Best Mustache",
  ]

  const [selectedTab, setSelectedTab] = useState(0) // Initialize selectedTab state

  const handleTabClick = index => {
    setSelectedTab(index)
  }
  const [loadMore, setLoadMore] = useState({
    currentPage: 1,
    lastPage: 1,
  })
  const dataHandler = async (nextPage = 1) => {
    const resp = await get(
      `/video/winner?page=${nextPage}&limit=4&filter=${tabsMenu[selectedTab]}`
    )
    if (resp?.data?.success) {
      if (loadmore) {
        setData(prev => [...prev, ...resp?.data?.data])
        setLoadMore({
          currentPage: resp?.data?.currentPage,
          lastPage: resp?.data?.totalPages,
        })
      } else setData(resp.data.data)
    }
  }

  const handleLoadMore = () => {
    const nextPage = loadMore.currentPage + 1
    dataHandler(nextPage)
  }
  useEffect(() => {
    if (loadmore) {
      setData([])
      setLoadMore({
        currentPage: 1,
        lastPage: 1,
      })
    }
    dataHandler()
  }, [selectedTab])

  return (
    <section className="px-0 px-lg-3">
      <div className="pt-0 pt-md-2 px-2 px-lg-5">
        <Row className="justify-content-center">
          <Col xs="12" className="">
            <Row className="align-items-baseline">
              {loadMore && (
                <Col md={12}>
                  <div className="d-flex flex-column  ">
                    <h2 className="text-uppercase ff-cooper font-size-56 fw-normal ">
                      last month's winners
                    </h2>
                    {/* <span className="text-uppercase text-darkcolor fw-bold ff-barlow font-size-40 ">
                      <ShuffleText text="Last Month winners" />
                    </span> */}
                    {/* <span className=" text-darkcolor fw-noraml ff-barlow font-size-26 mb-3 ">
                      Last Month’s Winning Joke Videos:
                    </span> */}
                    <div className="d-flex flex-wrap gap-2 ">
                      {tabsMenu.map((el, index) => (
                        <Link
                          key={index}
                          className={`text-capitalize py-1 px-3 text-darkcolor font-size-14 bg-button  ${
                            selectedTab === index ? "selected-tab" : ""
                          }`}
                          onClick={() => handleTabClick(index)}
                        >
                          {el}
                        </Link>
                      ))}
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </Col>
          <Col className="col-12 mt-2 mt-md-4">
            <div
              className={`${loadmore ? "" : "scrollContainer"} overflow-auto`}
            >
              <div
                className={
                  loadmore
                    ? "customGrid"
                    : data.length > 8
                    ? "custom-gallery"
                    : data.length >= 1
                    ? "customGrid"
                    : "h-25"
                }
              >
                {data.length >= 1 ? (
                  data.map((el, index) => (
                    <Link
                      to={`/videodetail/${el?.videoId?._id}`}
                      key={index}
                      className="position-relative mItem image-container"
                    >
                      <img
                        src={`${process.env.REACT_APP_S3_BUCKET}${el?.videoId?.videoDetail?.thumbnail?.path}`}
                        alt="pic"
                        className={`img-fluid w-100 rounded-4 ${
                          (loadmore || data.length <= 8) &&
                          "videoCard-thumbnail object-fit-cover"
                        }`}
                      />
                      <div className="position-absolute top-0 start-0 ms-2  mt-1 ">
                        <div
                          className="position-absolute top-0 rounded-pill mt-1 ms-2 py-1 icon-container  bg-white   d-flex align-items-center "
                          style={{ height: "52px" }}
                        >
                          <Link to={`/userprofile/${el?.userId?._id}`}>
                            {el?.userId ? (
                              <img
                                src={
                                  el?.userId?.additionalInfo?.profileImage
                                    ? process.env.REACT_APP_S3_BUCKET +
                                      el?.userId?.additionalInfo?.profileImage
                                    : personPlaceholder
                                }
                                alt="icon"
                                width={40}
                                height={40}
                                style={{
                                  borderRadius: "50px",
                                  objectFit: "cover",
                                }}
                                className="rounded-circle object-fit-cover"
                              />
                            ) : (
                              <Placeholder
                                style={{ width: 40, height: 40 }}
                                className="rounded-circle"
                              />
                            )}
                          </Link>
                          <span className="text-black mx-1 font-size-15 font-weight-500 text-nowrap icon-title">
                            <Link to={`/userprofile/${el?.userId?._id}`}>
                              {el?.userId?.firstName} {el?.userId?.lastName}
                            </Link>
                          </span>
                        </div>
                        <div className="position-absolute mt-5">
                          <div className="icon-text-overlay bg-white text-uppercase font-size-14 text-center  mt-3">
                            {el.tag}
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <Placeholders type="winner" />
                )}
              </div>
            </div>
          </Col>
        </Row>
        {/* {loadMore.lastPage !== loadMore.currentPage && (
          <Row className="justify-content-center mb-5 pb-5">
            <div className="text-center pt-5">
              <button
                className="text-uppercase font-size-14 btn btn-primary-green"
                onClick={handleLoadMore}
              >
                Come on, show us what you’ve got!{" "}
              </button>
            </div>
          </Row>
        )} */}
        {/* <Col className=" d-flex align-items-center justify-content-center pt-5 mt-md-0 pb-5">
          {!loadmore && (
            <Link to="/winner">
              <button className="text-uppercase font-size-14 font-weight-400 btn btn-primary-green ms-auto">
                view all videos
              </button>
            </Link>
          )}
        </Col> */}
      </div>
    </section>
  )
}

export default WinnerFromLastMonth
