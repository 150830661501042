import React from "react"
import { Card, CardBody, CardTitle, CardText, Col, Row } from "reactstrap"
import connect from "assets/images/dollarconnect.png"
import laugh from "assets/images/laugh.png"
import compete from "assets/images/compete.png"
import { Link } from "react-router-dom"

// Define the custom styles including the animation
const styles = {
  gradientBorder: {
    background: "linear-gradient(45deg, #FFDF2B, #126586, #F1803B, #1D3860)",
    borderRadius: "12px",
    padding: "4px",
    // Ensure the gradient is large enough to move
    animation: "borderAnimation 2s linear infinite", // Animation defined to move the background
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    color: "#fff",
  },
}

const keyframes = `
  @keyframes borderAnimation {
    0% {
      background-position: 0% 50%; // Starting position
    }
    
    100% {
      background-position: 0% 50%; // End position, looping back
    }
  }
`
// Inject the keyframes into the document's style
const styleSheet = document.styleSheets[0]
styleSheet.insertRule(keyframes, styleSheet.cssRules.length)

// CustomCard component to display individual cards
const CustomCard = ({ color1, color2, image, title, description }) => (
  <Col md="4" className="mb-4">
    <div style={{ ...styles.gradientBorder, backgroundSize: "" }}>
      <Card
        style={{
          ...styles.card,
          background: `linear-gradient(to bottom, ${color1} 0%, ${color2} 100%)`,
        }}
      >
        <CardBody className="d-flex flex-column align-items-center justify-content-center text-center">
          <div className="rounded-4 d-flex align-items-center justify-content-center ">
            <img src={image} alt={title} width={160} height={160} />
          </div>
          <CardTitle className="ff-cooper text-white fw-normal font-size-48 ">
            {title}
          </CardTitle>
          <CardText className="ff-proxima fw-normal font-size-24 ">
            {description}
          </CardText>
        </CardBody>
      </Card>
    </div>
  </Col>
)

const CardContainer = () => {
  const cardData = [
    {
      color1: "#AFDBC3",
      color2: "#3B7555",
      image: compete,
      title: "CONNECT",
      description:
        "Team up with friends & family to show us your jokes & connect with other dad joke enthusiasts in our community.",
    },
    {
      color1: "#126586",
      color2: "#1D3860",
      image: laugh,
      title: "LAUGH",
      description:
        "Whether you're having fun making jokes or finding new ones that make you smile- you’ll find plenty of reason to laugh at the Dockies.",
    },
    {
      color1: "#FFDF2B",
      color2: "#F1803B",
      image: connect,
      title: "COMPETE",
      description:
        "Compete in the Dockies monthly joke competitions to win awesome prizes. Plus all winners are featured on Dock Tok social media.",
    },
  ]

  return (
    <section id="how-dock-tok-work" className="py-5">
      <p
        className="text-uppercase fw-normal ff-cooper font-size-56  text-center"
        style={{ color: "#1D3860" }}
      >
        Join The dockies to
      </p>
      <div className="">
        <Row className="">
          {cardData.map((card, index) => (
            <CustomCard
              key={index}
              color1={card.color1}
              color2={card.color2}
              image={card.image}
              title={card.title}
              description={card.description}
            />
          ))}
        </Row>
        <div className="text-center">
          {" "}
          <Link
            to={"/login"}
            className="btn btn-primary-yellow font-size-14 font-weight-500 text-black bg-yellow text-uppercase text-center"
          >
            Join <span className="d-none d-xxl-inline-block">Our </span>
            <span className="d-none- d-md-inline-block">&nbsp;Community</span>
          </Link>{" "}
        </div>
      </div>
    </section>
  )
}

export default CardContainer
