import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import slide from "../../../assets/images/slide.png"
import slide1 from "../../../assets/images/slide1.png"
import slide2 from "../../../assets/images/slide2.png"

import touch from "assets/images/touch.png"
import award from "assets/images/award-01.png"
import upload from "assets/images/upload-square.png"

function CallToAction() {
  const cardData = [
    {
      img: upload,
      title: "Submit",
      description: "Videos By",
      date: "November 21",
    },
    {
      img: touch,
      title: "Vote",
      description: "Until",
      date: "November 21",
    },
    {
      img: award,
      title: "Winner",
      description: "Announced",
      date: "November 21",
    },
  ]

  return (
    <section className="bg-primary px-3 py-5 mt-5">
      <div className="px-5">
        <Row className="align-items-center">
          <Col md="8" className="text-md-left mb-4 mb-md-0">
            <h2 className="ff-cooper font-size-56 fw-normal">
              YOU COULD BE FEATURED NEXT MONTH!
            </h2>
            <Row className="mt-4">
              {cardData.map((card, index) => (
                <Col xs="4" className="text-center" key={index}>
                  <div className="d-flex flex-column flex-xl-row align-items-center">
                    <Card
                      className="info-card rounded-4"
                      style={{
                        backgroundColor: "#D7F5E5",
                        height: "120px",
                        width: "120px",
                        border: "none",
                      }}
                    >
                      <CardBody className="d-flex justify-content-center align-items-center">
                        <img
                          src={card.img}
                          alt={card.title}
                          height={45}
                          width={45}
                        />
                      </CardBody>
                    </Card>

                    <p
                      className="text-start ps-2 ff-proxima gray-700 fw-normal font-size-24"
                      style={{ lineHeight: "29px" }}
                    >
                      {card.title}
                      <br /> {card.description}
                      <br />
                      <strong className="ff-proxima text-black fw-bold font-size-24 pt-2">
                        {card.date}
                      </strong>
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
            <button className="btn btn-primary-yellow ff-proxima font-size-14 fw-bold">
              SUBMIT YOUR VIDEO NOW
            </button>
          </Col>
          <Col md="4" className="text-center overflow-hidden">
            <div className="position-relative d-flex justify-content-center gap-1">
              <img
                src={slide}
                alt="Excited Person"
                className="stacked-image position-absolute"
                style={{ zIndex: "3" }}
              />
              <img
                src={slide1}
                alt="Excited Person"
                className="stacked-image"
                style={{ zIndex: "2", marginRight: "-1px" }}
              />
              <img
                src={slide2}
                alt="Excited Person"
                className="stacked-image"
                style={{ zIndex: "1" }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default CallToAction
