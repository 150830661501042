// Hero.js
import React from "react"
import { Container } from "reactstrap"

const Header = () => (
  <section className="hero header-blue-bg ">
    <Container fluid>
      {" "}
      <p className="ff-cooper font-size-120 fw-normal text-start transparent-text-border d-block m-0 p-0 lh-1">
        we are the
      </p>
      <p className="ff-cooper font-size-224 text-start  primary-green-text d-block m-0 p-0 lh-1 text-uppercase">
        Dockies
      </p>
      <p
        className="ffp text-uppercase fw-bold font-size-32 text-start text-lg-center text-xl-end me-2 me-xl-0"
        style={{ fontStyle: "italic" }}
      >
        Dad joke headquarter
      </p>
    </Container>
  </section>
)

export default Header
