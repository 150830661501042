import React, { useEffect, useState } from "react"
import { Button, Col, Container, Row } from "reactstrap"
import play from "assets/images/home/play.svg"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import { Link, useNavigate } from "react-router-dom"
import ShuffleText from "components/animation/ShullfeText/ShullfeText"
import { useSelector } from "react-redux"
import { Bounce, toast } from "react-toastify"
import CarmeraIcon from "../../../assets/images/dockIcon/video-02.svg"
import ScissorIcon from "../../../assets/images/dockIcon/scissor-01.svg"
import AdsIcon from "../../../assets/images/dockIcon/noadvertisiment.svg"
import AlertIcon from "../../../assets/images/dockIcon/alert-02.svg"
import PhoneIcon from "../../../assets/images/dockIcon/smart-phone-01.svg"
import TimerIcon from "../../../assets/images/dockIcon/timer-01.svg"
import HonorIcon from "../../../assets/images/dockIcon/honor.svg"
import PolicyIcon from "../../../assets/images/dockIcon/policy.svg"
import ChildIcon from "../../../assets/images/dockIcon/child.svg"
import UserGroupIcon from "../../../assets/images/dockIcon/user-group-02.svg"
import FilmIcon from "../../../assets/images/dockIcon/film-01.svg"
import DViemIcon from "../../../assets/images/dockIcon/3-d-view.svg"
import MoonIcon from "../../../assets/images/dockIcon/moon-landing.svg"
import WindIcon from "../../../assets/images/dockIcon/wind-turbine.png"
import pic1 from "assets/images/dockIcon/12.png"

const HowDockTokWorks = () => {
  const { isAuth } = useSelector(state => state.auth)
  const navigate = useNavigate()
  const topic = localStorage.getItem("topic")

  const handleSubmitClick = () => {
    if (isAuth) {
      navigate(`/challenge-detail/${topic}`)
    } else {
      toast.error("Please Sign in to Submit Your Video.", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
      navigate("/login")
    }
  }
  return (
    <>
      <section id="how-dock-tok-works" className="py-5 ">
        <Container fluid className="py-5 py-md-5 px-2 bg-hero rounded-4">
          <h2 className="fw-bold font-size-40 text-white text-center text-uppercase">
            monthly competition rules{" "}
          </h2>
          {/* <p className="font-size-20  text-center text-white  pb-5 ">
            <ShuffleText text="We are all fun & games with a few house rules" />
          </p> */}

          <Row className=" pt-4">
            <Col xs="12" md={12} lg={6} className="">
              <ul style={{ listStyleType: "none" }} className="ruleUl">
                <li className="font-size-18 text-white">
                  <img src={CarmeraIcon} alt={"Video"} className={"ulIcons"} />{" "}
                  Must be a joke video
                </li>

                <li className="font-size-18 text-white">
                  <img src={TimerIcon} alt={"Video"} className={"ulIcons"} />{" "}
                  Must be less than 60 seconds
                </li>
                <li className="font-size-18 text-white">
                  <img src={AlertIcon} alt={"Video"} className={"ulIcons"} /> No
                  nudity
                </li>

                <li className="font-size-18 text-white">
                  <img src={PhoneIcon} alt={"Video"} className={"ulIcons"} />{" "}
                  Must be filmined in vertical format (9:16 aspect is perfect)
                </li>
                <li className="font-size-18 text-white">
                  <img src={ChildIcon} alt={"Video"} className={"ulIcons"} />{" "}
                  Must be 13 years old to participate
                </li>
              </ul>
            </Col>
            <Col xs="12" md={12} lg={6} className="pb-0">
              <ul style={{ listStyleType: "none" }} className="ruleUl">
                <li className="font-size-18 text-white">
                  <img src={ScissorIcon} alt={"Video"} className={"ulIcons"} />{" "}
                  No swearing, unless censored out
                </li>
                <li className="font-size-18 text-white">
                  <img src={AlertIcon} alt={"Video"} className={"ulIcons"} /> No
                  nudity
                </li>
                <li className="font-size-18 text-white">
                  <img src={AdsIcon} alt={"Video"} className={"ulIcons"} /> No
                  promotions of any kind (products or personal)
                </li>
                <li className="font-size-18 text-white">
                  <img src={HonorIcon} alt={"Video"} className={"ulIcons"} />{" "}
                  Avoid misinformation: Don't spread misinformation from
                  non-credible sources.
                </li>
                <li className="font-size-18 text-white">
                  <img src={PolicyIcon} alt={"Video"} className={"ulIcons"} />{" "}
                  We have a zero tolerance policy for bullying
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="font-size-20  text-center text-white  pt-3 ">
                <ShuffleText
                  text="Each video submitted is reviewed by our team to ensure
                compliance & to keep our community clean & fun."
                />
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default HowDockTokWorks
