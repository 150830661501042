import React, { useEffect, useState, useRef } from "react"
import { Container, Row, Col } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import ShuffleText from "components/animation/ShullfeText/ShullfeText"
import AnimatedText from "components/animation/AnimatedText/AnimatedText"
import mobilesketch from "assets/images/mobile-sketch.png"
import Features from "./Features"
import VotingSection from "./VotingSection"

const Hero = ({
  inSection = true,
  showHeaderText = true,
  showButton = true,
  showChallangeText = true,
  className = "",
  setRules = () => {},
  rules = "",
  videoClassName = "",
  sketchClassName = "",
}) => {
  const [topic, setTopic] = useState({})
  const videoRef = useRef(null) // Add a ref for the video element

  const contentHandler = async () => {
    const resp = await get(`${endpoints.topic}?page=1&limit=1&filter=featured`)
    if (resp?.data?.success) {
      setTopic(resp?.data?.data[0])
      rules == "set" && setRules(resp?.data?.data?.at(0).rules)
      localStorage.setItem("topic", resp?.data?.data?.at(0)?._id)
    }
  }

  useEffect(() => {
    contentHandler()
  }, [])

  const navigate = useNavigate()

  const handleClick = () => {
    window.scrollTo(0, 0)
    navigate("/")
  }

  // Function to toggle play/pause on video click
  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play()
      } else {
        videoRef.current.pause()
      }
    }
  }
  const [volume, setVolume] = useState(0) // Initial volume at 50%

  const handleVolumeChange = e => {
    videoRef.current.muted = false
    const newVolume = e.target.value / 100 // Convert range value (0-100) to 0-1 for volume
    setVolume(newVolume)
    if (!videoRef.current.paused) {
      videoRef.current.volume = parseFloat(newVolume).toFixed(1)
    }
  }
  return (
    <section
      style={{
        background: "linear-gradient(to top, #ffffff, #CCFFE3, #ffffff)",
      }}
    >
      <Container fluid>
        <Row className="justify-content-center py-5">
          <Col
            className={`col-12 ${
              inSection ? "bg-her   rounded-4 my-5 pt-5" : ""
            }`}
          >
            <div
              className={`d-flex flex-column h-100 ${
                inSection ? " " : "py-5 px-0"
              }`}
            >
              {topic ? (
                <Row className="">
                  {showChallangeText && (
                    <Col
                      xs={12}
                      sm={1}
                      lg={1}
                      xl={1}
                      xxl={1}
                      className={
                        "bg-b text-white text-center  overflow-visible d-none d-lg-flex ${dockieClassName} "
                      }
                    >
                      <p className="vertical-text text-uppercase ff-cooper mb-0 text-black  pt-5 ps-lg-0  font-size-60 ps-xl-0  d-flex justify-content-center  align-items-center p-0 m-0">
                        The Dockies
                      </p>
                    </Col>
                  )}
                  <Col
                    xs={12}
                    sm={6}
                    md={5}
                    lg={4}
                    xl={4}
                    xxl={3}
                    className="mobileVideoContainer text-center text-md-start px-0 px-2 px-sm-0 ps-sm-2 ps-lg-0 pt-0 mt-0 mb-0 pb-0 position-relative pt-4 pt-sm-0"
                  >
                    <div className="position-relative w-100 ">
                      {/* Video Background */}
                      <div className="volumeContainer">
                        <span>
                          <i className="fa-regular fa-volume-up"></i>
                        </span>
                        <input
                          orient="vertical"
                          type="range"
                          value={volume * 100} // Convert volume back to 0-100 scale for the slider
                          onChange={handleVolumeChange}
                          class="form-range"
                          id="customRange1"
                        />
                      </div>
                      <video
                        ref={videoRef} // Add the ref to the video element
                        className={`video-background  ${videoClassName}`}
                        src={`${process.env.REACT_APP_S3_BUCKET}${topic?.videoDetail?.video?.Key}`}
                        poster={`${process.env.REACT_APP_S3_BUCKET}${topic?.videoDetail?.thumbnail?.path}`}
                        controls={false}
                        playsInline
                        autoPlay
                        loop
                        muted
                        // Add the click handler
                      />
                      {/* Mobile Sketch Image */}
                      <img
                        onClick={togglePlayPause}
                        src={mobilesketch}
                        className={`img-fluid rounded-2 mobile-sketch py-2 px-2 py-sm-2 py-lg-0 px-lg-0 ${sketchClassName}`}
                        alt="Mobile Sketch"
                      />
                    </div>
                  </Col>

                  <Col
                    xs={12}
                    sm={6}
                    md={7}
                    lg={6}
                    xl={6}
                    xxl={7}
                    className="px-4 px-sm-4 px-md-5 py-2 py-sm-4 py-md-5 py-lg-0"
                  >
                    <div className="d-flex flex-column h-100 justify-content-center">
                      <div className="">
                        {showChallangeText && (
                          <div className="mb-2 mt-2 ">
                            <p className="ff-proxima font-size-48  mb-0 pb-0 fw-bold text-black text-uppercase custom-h2">
                              <ShuffleText text="The FIRST online dad joke community" />
                            </p>
                            {/* <p className="font-size-15 fw-medium text-black pt-1 mt-0">
                              <AnimatedText
                                el="span"
                                text={`The Dockies is THE first online dad joke community where YOU can share your best jokes with the Dock Tok World.`}
                              />
                            </p> */}
                          </div>
                        )}
                        <span
                          className={`font-size-16 ${
                            inSection ? "text-black" : "gray-700"
                          }`}
                          dangerouslySetInnerHTML={{
                            __html: topic.description,
                          }}
                        ></span>

                        {showButton && (
                          <Link to={`/challenge-detail/${topic?._id}`}>
                            <button
                              type="button"
                              className="btn btn-primary-yellow text-uppercase mb-5 mb-md-2 ff-proxima font-size-14 fw-bold"
                              onClick={handleClick}
                            >
                              join our community
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                <div className="d-flex justify-content-center">
                  <span className="font-size-26 text-darkcolor text-uppercase fw-medium">
                    no header video
                  </span>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Features />
        </Row>
        <Row>
          <VotingSection />
        </Row>
      </Container>
    </section>
  )
}

export default Hero
