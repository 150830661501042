import React from "react"
import { Navigate } from "react-router-dom"

// // Pages Component
import Chat from "../pages/Chat/Chat"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

// //Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index"
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail"
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index"
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index"
import EcommerceCart from "../pages/Ecommerce/EcommerceCart"
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout"
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index"
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailBasicTemplte from "../pages/Email/email-basic-templte"
import EmailAlertTemplte from "../pages/Email/email-template-alert"
import EmailTemplateBilling from "../pages/Email/email-template-billing"

//Invoices
import InvoicesList from "../pages/Invoices/invoices-list"
import InvoiceDetail from "../pages/Invoices/invoices-detail"

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid"
import ContactsList from "../pages/Contacts/ContactList/contacts-list"
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile"

//Pages
import Home from "../pages/Home"
import HomePage from "../pages/HomePage"
import VideoDetail from "../pages/Videos/VideoDetail/VideoDetail2"
import PrivacyPolicy from "../pages/PrivacyPolicy/Privacy"
import TermCondition from "../pages/TermCondition/Term"
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiColors from "../pages/Ui/UiColors"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGeneral from "../pages/Ui/UiGeneral"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiOffcanvas from "../pages/Ui/UiOffcanvas"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiPlaceholders from "../pages/Ui/UiPlaceholders"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiToasts from "../pages/Ui/UiToasts"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRating from "../pages/Ui/UiRating"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"
import UiNotifications from "../pages/Ui/ui-notifications"
import UiImageCropper from "../pages/Ui/ui-image-cropper"

// Forms
import BasicElements from "../pages/Forms/BasicElements"
import FormLayouts from "../pages/Forms/FormLayouts"
import FormAdvanced from "../pages/Forms/FormAdvanced"
import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormMask from "../pages/Forms/FormMask"
import FormRepeater from "../pages/Forms/FormRepeater"
import FormUpload from "../pages/Forms/FormUpload"
import FormWizard from "../pages/Forms/FormWizard"
import FormXeditable from "../pages/Forms/FormXeditable"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import EditableTables from "../pages/Tables/EditableTables"

// Charts
import ChartApex from "../pages/Charts/Apexcharts"
import ChartjsChart from "../pages/Charts/ChartjsChart"
import EChart from "../pages/Charts/EChart"
import SparklineChart from "../pages/Charts/SparklineChart"
import ChartsKnob from "../pages/Charts/charts-knob"

//Icons

import IconUnicons from "../pages/Icons/IconUnicons"
import IconBoxicons from "../pages/Icons/IconBoxicons"
import IconDripicons from "../pages/Icons/IconDripicons"
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import IconFontawesome from "../pages/Icons/IconFontawesome"

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"

// File Manager
import FileManager from "../pages/FileManager"

// Authentication related pages
import TikTok from "../pages/Authentication/TikTok"
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ConfirmPasswordPage from "../pages/Authentication/ConfirmPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Profile
import UserProfile from "../pages/Authentication/user-profile"
import UiUtilities from "../pages/Ui/ui-utilities"

// Video
import EditVideo from "../pages/Videos/EditVideo/EditVideo"
import ListVideo from "../pages/Videos/List-Video/ListVideo"

// User
import AddUser from "../pages/Users/Add-User/AddUser"
import ListUser from "../pages/Users/List-User/ListUser"
import ListChallenges from "pages/Challenges/List/ListChallenges"
import DetailChallenges from "pages/Challenges/Detail/DetailChallenges"
import UserProfilee from "pages/UserProfilee/Profilee/UserProfilee"
import TheDockies from "../pages/TheDockies/components/TheDockies"
import VideoVaults from "../pages/VideoVaults/components/VideoVaults"
import EditProfile from "pages/EditProfile/components/EditProfile"
import NotificationsModal from "pages/Notifications/components/NotificationsModal"
import AdminDockies from "pages/AdminDockies/components/AdminDockies"
import About from "pages/About/components/About"
import BlockedUsers from "pages/BlockedUsers/components/BlockedUsers"

// Admin
import { AdminDashboard } from "../pages/adminDashboard/index"
import AdminLogin from "../pages/AdminLogin/index"
import Admin2FA from "../pages/AdminLogin/Admin2FA"
import { Topics } from "../pages/Topic/index"
import { EditTopic } from "../pages/Topic/AddTopic/index"
import { TopicDetails } from "../pages/Topic/components/TopicDetails"
import { Blogs } from "../pages/Blogs/index"
import { BlogDetails } from "../pages/Blogs/components/BlogDetails"
import { EditBlog } from "../pages/Blogs/AddBlog/index"
import Allvideos from "../pages/AllVideos/index"
import AllvideosAward from "../pages/AllVideosAward/index"
import { StaffListing } from "../pages/Staff/components/StaffListing//StaffListing"
import { AddStaff } from "../pages/Staff/components/AddStaff/AddStaff"
import { EditStaff } from "../pages/Staff/components/AddStaff/Editstaff"
import AddAwards from "../pages/Awards/components/AddAwards/AddAwards"
import EditAwards from "../pages/Awards/components/AddAwards/EditAwards"
import AwardsListing from "../pages/Awards/components/AwardsListing//AwardsListing"

import AdminVideoVaults from "pages/AdminVideoVaults/components/AdminVideoVaults"

import { components } from "react-select"

const adminRoutes = [
  { path: "/admin-dashboard", component: <AdminDashboard /> },
  { path: "/staff", component: <StaffListing /> },
  { path: "/add-staff/:id", component: <EditStaff /> },
  { path: "/add-staff", component: <AddStaff /> },

  { path: "/awards-list", component: <AwardsListing /> },
  { path: "/add-awards/:id", component: <AddAwards /> },
  { path: "/add-awards", component: <AddAwards /> },

  { path: "/dashboard", component: <Dashboard /> },
  { path: "/header-videos", component: <Topics /> },
  { path: "/add-header-video", component: <EditTopic /> },
  { path: "/edit-header-video/:id", component: <EditTopic /> },
  { path: "/header-video-details/:id", component: <TopicDetails /> },
  { path: "/how-it-works", component: <Blogs /> },
  { path: "/add", component: <EditBlog /> },
  { path: "/all-videos/:id", component: <Allvideos /> },
  { path: "/winner", component: <AllvideosAward /> },
  { path: "/edit-details/:id", component: <EditBlog /> },
  { path: "/how-it-works-details/:id", component: <BlogDetails /> },
  { path: "/list-challenges", component: <ListChallenges /> },
  { path: "/challenge-detail/:id", component: <DetailChallenges /> },
  { path: "/userprofile/:id", component: <UserProfilee /> },
  { path: "/editprofile", component: <EditProfile /> },

  { path: "/notificationlist", component: <NotificationsModal /> },
  { path: "/admindockies", component: <AdminDockies /> },

  { path: "/users", component: <BlockedUsers /> },
  { path: "/admin-videovaults", component: <AdminVideoVaults /> },

  { path: "/edit-video/:id", component: <EditVideo /> },
  { path: "/list-hotel", component: <ListVideo /> },
  { path: "/add-transport", component: <AddUser /> },
  { path: "/list-transport", component: <ListUser /> },

  { path: "/calendar", component: <Calendar /> },
  //chat
  { path: "/chat", component: <Chat /> },

  //Ecommerce
  { path: "/ecommerce-products", component: <EcommerceProducts /> },
  { path: "/ecommerce-product-detail", component: <EcommerceProductDetail /> },
  {
    path: "/ecommerce-products-detail/:id",
    component: <EcommerceProductDetail />,
  },
  { path: "/ecommerce-orders", component: <EcommerceOrders /> },
  { path: "/ecommerce-customers", component: <EcommerceCustomers /> },
  { path: "/ecommerce-cart", component: <EcommerceCart /> },
  { path: "/ecommerce-checkout", component: <EcommerceCheckout /> },
  { path: "/ecommerce-shops", component: <EcommerceShops /> },
  { path: "/ecommerce-add-product", component: <EcommerceAddProduct /> },

  //Email
  { path: "/email-inbox", component: <EmailInbox /> },
  { path: "/email-read", component: <EmailRead /> },
  { path: "/email-template-basic", component: <EmailBasicTemplte /> },
  { path: "/email-template-alert", component: <EmailAlertTemplte /> },
  { path: "/email-template-billing", component: <EmailTemplateBilling /> },

  //Invoices
  { path: "/invoices-list", component: <InvoicesList /> },
  { path: "/invoices-detail", component: <InvoiceDetail /> },
  { path: "/invoices-detail/:id", component: <InvoiceDetail /> },

  // Contacts
  { path: "/contacts-grid", component: <ContactsGrid /> },
  { path: "/contacts-list", component: <ContactsList /> },
  { path: "/contacts-profile", component: <ContactsProfile /> },

  //Utility
  { path: "/pages-starter", component: <PagesStarter /> },
  { path: "/pages-timeline", component: <PagesTimeline /> },
  { path: "/pages-faqs", component: <PagesFaqs /> },
  { path: "/pages-pricing", component: <PagesPricing /> },

  // Ui
  { path: "/ui-alerts", component: <UiAlert /> },
  { path: "/ui-buttons", component: <UiButtons /> },
  { path: "/ui-cards", component: <UiCards /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-colors", component: <UiColors /> },
  { path: "/ui-dropdowns", component: <UiDropdown /> },
  { path: "/ui-general", component: <UiGeneral /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-lightbox", component: <UiLightbox /> },
  { path: "/ui-modals", component: <UiModal /> },
  { path: "/ui-offcanvas", component: <UiOffcanvas /> },
  { path: "/ui-progressbars", component: <UiProgressbar /> },
  { path: "/ui-placeholders", component: <UiPlaceholders /> },
  { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-toasts", component: <UiToasts /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
  { path: "/ui-rating", component: <UiRating /> },
  { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  { path: "/ui-notifications", component: <UiNotifications /> },
  { path: "/ui-image-cropper", component: <UiImageCropper /> },
  { path: "/ui-utilities", component: <UiUtilities /> },

  // Forms
  { path: "/basic-elements", component: <BasicElements /> },
  { path: "/form-layouts", component: <FormLayouts /> },
  { path: "/form-advanced", component: <FormAdvanced /> },
  { path: "/form-editors", component: <FormEditors /> },
  { path: "/form-mask", component: <FormMask /> },
  { path: "/form-repeater", component: <FormRepeater /> },
  { path: "/form-uploads", component: <FormUpload /> },
  { path: "/form-wizard", component: <FormWizard /> },
  { path: "/form-validation", component: <FormValidations /> },
  { path: "/form-xeditable", component: <FormXeditable /> },

  // Tables
  { path: "/tables-basic", component: <BasicTables /> },
  { path: "/tables-datatable", component: <DatatableTables /> },
  { path: "/tables-responsive", component: <ResponsiveTables /> },
  { path: "/tables-editable", component: <EditableTables /> },

  //Charts
  { path: "/apex-charts", component: <ChartApex /> },
  { path: "/chartjs-charts", component: <ChartjsChart /> },
  { path: "/e-charts", component: <EChart /> },
  { path: "/sparkline-charts", component: <SparklineChart /> },
  { path: "/charts-knob", component: <ChartsKnob /> },

  // Icons
  { path: "/icons-unicons", component: <IconUnicons /> },
  { path: "/icons-boxicons", component: <IconBoxicons /> },
  { path: "/icons-dripicons", component: <IconDripicons /> },
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-fontawesome", component: <IconFontawesome /> },

  // Maps
  { path: "/maps-google", component: <MapsGoogle /> },
  { path: "/maps-vector", component: <MapsVector /> },

  // File Manager
  { path: "/file-manager", component: <FileManager /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/dashboard/*",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
]
const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/header-videos", component: <Topics /> },
  { path: "/how-it-works", component: <Blogs /> },
  { path: "/all-videos/:id", component: <Allvideos /> },
  { path: "/winner", component: <AllvideosAward /> },
  { path: "/how-it-works-details/:id", component: <BlogDetails /> },
  { path: "/list-challenges", component: <ListChallenges /> },
  { path: "/challenge-detail/:id", component: <DetailChallenges /> },
  { path: "/userprofile/:id", component: <UserProfilee /> },

  { path: "/editprofile", component: <EditProfile /> },

  { path: "/notificationlist", component: <NotificationsModal /> },

  { path: "/blockedusers", component: <BlockedUsers /> },

  { path: "/edit-video/:id", component: <EditVideo /> },
  { path: "/list-hotel", component: <ListVideo /> },
  { path: "/add-transport", component: <AddUser /> },
  { path: "/list-transport", component: <ListUser /> },

  { path: "/calendar", component: <Calendar /> },
  //chat
  { path: "/chat", component: <Chat /> },

  //Ecommerce
  { path: "/ecommerce-products", component: <EcommerceProducts /> },
  { path: "/ecommerce-product-detail", component: <EcommerceProductDetail /> },
  {
    path: "/ecommerce-products-detail/:id",
    component: <EcommerceProductDetail />,
  },
  { path: "/ecommerce-orders", component: <EcommerceOrders /> },
  { path: "/ecommerce-customers", component: <EcommerceCustomers /> },
  { path: "/ecommerce-cart", component: <EcommerceCart /> },
  { path: "/ecommerce-checkout", component: <EcommerceCheckout /> },
  { path: "/ecommerce-shops", component: <EcommerceShops /> },
  { path: "/ecommerce-add-product", component: <EcommerceAddProduct /> },

  //Email
  { path: "/email-inbox", component: <EmailInbox /> },
  { path: "/email-read", component: <EmailRead /> },
  { path: "/email-template-basic", component: <EmailBasicTemplte /> },
  { path: "/email-template-alert", component: <EmailAlertTemplte /> },
  { path: "/email-template-billing", component: <EmailTemplateBilling /> },

  //Invoices
  { path: "/invoices-list", component: <InvoicesList /> },
  { path: "/invoices-detail", component: <InvoiceDetail /> },
  { path: "/invoices-detail/:id", component: <InvoiceDetail /> },

  // Contacts
  { path: "/contacts-grid", component: <ContactsGrid /> },
  { path: "/contacts-list", component: <ContactsList /> },
  { path: "/contacts-profile", component: <ContactsProfile /> },

  //Utility
  { path: "/pages-starter", component: <PagesStarter /> },
  { path: "/pages-timeline", component: <PagesTimeline /> },
  { path: "/pages-faqs", component: <PagesFaqs /> },
  { path: "/pages-pricing", component: <PagesPricing /> },

  // Ui
  { path: "/ui-alerts", component: <UiAlert /> },
  { path: "/ui-buttons", component: <UiButtons /> },
  { path: "/ui-cards", component: <UiCards /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-colors", component: <UiColors /> },
  { path: "/ui-dropdowns", component: <UiDropdown /> },
  { path: "/ui-general", component: <UiGeneral /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-lightbox", component: <UiLightbox /> },
  { path: "/ui-modals", component: <UiModal /> },
  { path: "/ui-offcanvas", component: <UiOffcanvas /> },
  { path: "/ui-progressbars", component: <UiProgressbar /> },
  { path: "/ui-placeholders", component: <UiPlaceholders /> },
  { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-toasts", component: <UiToasts /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
  { path: "/ui-rating", component: <UiRating /> },
  { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  { path: "/ui-notifications", component: <UiNotifications /> },
  { path: "/ui-image-cropper", component: <UiImageCropper /> },
  { path: "/ui-utilities", component: <UiUtilities /> },

  // Forms
  { path: "/basic-elements", component: <BasicElements /> },
  { path: "/form-layouts", component: <FormLayouts /> },
  { path: "/form-advanced", component: <FormAdvanced /> },
  { path: "/form-editors", component: <FormEditors /> },
  { path: "/form-mask", component: <FormMask /> },
  { path: "/form-repeater", component: <FormRepeater /> },
  { path: "/form-uploads", component: <FormUpload /> },
  { path: "/form-wizard", component: <FormWizard /> },
  { path: "/form-validation", component: <FormValidations /> },
  { path: "/form-xeditable", component: <FormXeditable /> },

  // Tables
  { path: "/tables-basic", component: <BasicTables /> },
  { path: "/tables-datatable", component: <DatatableTables /> },
  { path: "/tables-responsive", component: <ResponsiveTables /> },
  { path: "/tables-editable", component: <EditableTables /> },

  //Charts
  { path: "/apex-charts", component: <ChartApex /> },
  { path: "/chartjs-charts", component: <ChartjsChart /> },
  { path: "/e-charts", component: <EChart /> },
  { path: "/sparkline-charts", component: <SparklineChart /> },
  { path: "/charts-knob", component: <ChartsKnob /> },

  // Icons
  { path: "/icons-unicons", component: <IconUnicons /> },
  { path: "/icons-boxicons", component: <IconBoxicons /> },
  { path: "/icons-dripicons", component: <IconDripicons /> },
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-fontawesome", component: <IconFontawesome /> },

  // Maps
  { path: "/maps-google", component: <MapsGoogle /> },
  { path: "/maps-vector", component: <MapsVector /> },

  // File Manager
  { path: "/file-manager", component: <FileManager /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/dashboard/*",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
]

const authRoutes = [
  { path: "/admin", component: <AdminLogin /> },
  { path: "/otp", component: <Admin2FA /> },
  { path: "/home", component: <HomePage /> },
  { path: "/", component: <Home /> },

  { path: "/about", component: <About /> },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Register /> },
  { path: "/tiktok", component: <TikTok /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/confirm-password", component: <ConfirmPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/videodetail/:id", component: <VideoDetail /> },
  { path: "/thedockies", component: <TheDockies /> },
  { path: "/videovaults", component: <VideoVaults /> },
  { path: "/privacypolicy", component: <PrivacyPolicy /> },
  { path: "/termcondition", component: <TermCondition /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes, adminRoutes }
