// App.js
import React from "react"
import Header from "./components/Header"
import Hero from "./components/Hero"
import Features from "./components/Features"
import VotingSection from "./components/VotingSection"
import VideosSection from "./components/VideosSection"
import HowItWorks from "./components/HowItWorks"
import Rules from "./components/Rules"
import Winners from "./components/Winners"
import CallToAction from "./components/CallToAction"
import VideosPostedByDockies from "../Home/components/VideosPostedByDockies"

function HomePage() {
  const videos = [] // Sample data
  const winners = [] // Sample data

  return (
    <div className="App">
      <Header />
      <Hero />
      <VideosSection videos={videos} />
      <HowItWorks />
      <Rules />
      <Winners />
      <CallToAction />
    </div>
  )
}

export default HomePage
