import React, { useState } from "react"
import {
  NavbarToggler,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Col,
} from "reactstrap"
import { NavLink, useNavigate, Link } from "react-router-dom"
import { useSelector } from "react-redux"
import personPlaceholder from "assets/images/personPlaceholder.png" // Placeholder image
import { ReactComponent as HomeIcon } from "assets/images/home-icon.svg"
import { ReactComponent as DashboardIcon } from "assets/images/dash-icon.svg"
import { ReactComponent as AboutIcon } from "assets/images/about-icon.svg"
import { ReactComponent as DockieIcon } from "assets/images/dockie-icon.svg"
import { ReactComponent as VideoIcon } from "assets/images/video-vaults-icon.svg"
import { ReactComponent as WorkIcon } from "assets/images/how-it-works-icon.svg"

const TopNavOffCanvas = () => {
  const [show, setShow] = useState(false)
  const toggle = () => setShow(!show)

  // Access the auth state from Redux
  const { isAuth, currentUser } = useSelector(state => state.auth)
  const navigate = useNavigate()

  // Links to be displayed in the Offcanvas
  const links = [
    {
      id: "link12",
      title: "Home",
      path: "/",
      iconSVG: <HomeIcon className="me-1" />,
    },
    {
      id: "link8",
      title: "Dashboard",
      path:
        currentUser?.userRole == "subscriber"
          ? "/dashboard"
          : "/admin-dashboard",
      iconSVG: <DashboardIcon className="me-1" />,
    },
    {
      id: "link21",
      title: "About",
      path: "/about",
      iconSVG: <AboutIcon className="me-1" />,
    },
    {
      id: "link61",
      title: "The Dockie Awards",
      path: "/thedockies",
      iconSVG: <DockieIcon className="me-1" />,
    },
    {
      id: "link51",
      title: "Video Vault",
      path: "/videovaults",
      iconSVG: <VideoIcon className="me-1" />,
    },
    {
      id: "link71",
      title: "How It Works",
      path: "/how-it-works",
      iconSVG: <WorkIcon className="me-1" />,
    },
  ]
  const legalLinks = [
    { title: "Privacy Policy", path: "/privacypolicy" },
    { title: "Terms & Conditions", path: "/termcondition" },
  ]
  const handleLogout = () => {
    localStorage.removeItem("authUser")
    navigate("/")
  }

  return (
    <div>
      <NavbarToggler onClick={toggle} />
      <Offcanvas
        direction="end"
        isOpen={show}
        toggle={toggle}
        style={{ width: "250px" }}
      >
        <OffcanvasHeader toggle={toggle}>
          <div className="d-flex align-items-center w-100">
            <Col className="text-end">
              {isAuth ? (
                <Link to={`/userprofile/${currentUser?._id}`}>
                  <div className="d-flex align-items-center">
                    <img
                      className="rounded-circle header-profile-user"
                      style={{
                        objectFit: "cover",
                        width: "40px",
                        height: "40px",
                      }}
                      src={
                        currentUser?.additionalInfo?.profileImage
                          ? process.env.REACT_APP_S3_BUCKET +
                            currentUser?.additionalInfo?.profileImage
                          : personPlaceholder
                      }
                      alt="Profile"
                    />
                    <span className="ms-2 fw-medium font-size-15 text-dark">
                      {currentUser?.firstName || "User"}
                    </span>
                  </div>
                </Link>
              ) : (
                <NavLink to="/login" className="ms-auto">
                  <button className="btn btn-primary-green">Login</button>
                </NavLink>
              )}
            </Col>
          </div>
        </OffcanvasHeader>

        <OffcanvasBody>
          <ul className="nav flex-column gap-3 px-0 ">
            {links.map((link, index) => (
              <li className="nav-item ff-barlow" key={index}>
                <NavLink
                  to={link.path}
                  className={({ isActive }) =>
                    isActive
                      ? "pre-text text-uppercase font-size-14 fw-medium"
                      : "text-secondary text-uppercase font-size-14 fw-medium"
                  }
                  onClick={toggle}
                >
                  {link.iconSVG} {link.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </OffcanvasBody>

        <div className="py-4 ms-2 ps-2">
          {isAuth && (
            <NavLink to="/logout">
              <button
                className="btn btn-primary-green mb-2"
                onClick={handleLogout}
              >
                Logout
              </button>
            </NavLink>
          )}

          <div>
            {legalLinks.map((link, index) => (
              <div key={index}>
                <NavLink
                  to={link.path}
                  className={({ isActive }) =>
                    isActive
                      ? "text-uppercase font-size-14 fw-medium pre-text"
                      : "text-uppercase font-size-14 fw-medium text-secondary"
                  }
                  onClick={toggle}
                >
                  {link.title}
                </NavLink>
                <br />
              </div>
            ))}
          </div>
        </div>
      </Offcanvas>
    </div>
  )
}

export default TopNavOffCanvas
