import React, { useEffect, useState } from "react"
import { Link, NavLink } from "react-router-dom"
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  Row,
} from "reactstrap"
import search from "../../../assets/images/searchicon.png"
import logoLg from "../../../assets/images/logowhite.png"

import TopNavOffCanvas from "./TopNavOffCanvas"
import { useSelector } from "react-redux"
import ProfileMenu from "components/CommonForBoth/TopbarDropdown/ProfileMenu"
import SearchDropDown from "./SearchVideo"

const links = [
  {
    id: "link2",
    title: "About",
    path: "/about",
  },
  {
    id: "link6",
    title: "The Dockie Awards",
    path: "/thedockies",
  },
  {
    id: "link5",
    title: "Video Vault",
    path: "/videovaults",
  },
]

const TopNavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { isAuth } = useSelector(state => state.auth)
  const toggle = () => setDropdownOpen(prevState => !prevState)
  const handleCloseDropdown = () => setDropdownOpen(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(typeof window !== "undefined" && window?.scrollY > 0)
    }
    handleScroll()
    typeof window !== "undefined" &&
      window.addEventListener("scroll", handleScroll)
    return () => {
      typeof window !== "undefined" &&
        window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <Navbar
      className={`top-nav-bar header-blue-bg ${isScrolled ? "" : "bg-blac"}`}
      // style={{
      //   background: "linear-gradient(90deg, #146283, #1D3860)", // top to bottom
      // }}
    >
      <Row className="align-items-center justify-content-center py-lg-4 w-100 g-0">
        <Col xs="4" className="col-lg-2">
          <div>
            <NavLink to="/" onClick={onClick}>
              <img src={logoLg} alt="logo" className="img-fluid" />
            </NavLink>
          </div>
        </Col>
        <Col className="col-lg-8 d-lg-block d-none">
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <ul className="nav gap-2 gap-sm-4 border border-secondary-subtle border-2 rounded-pill px-3 py-2 text-secondary fw-medium">
              <li className="nav-item">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? "text-yellow text-uppercase font-size-14 fw-medium"
                      : "text-white text-uppercase font-size-14 fw-medium"
                  }
                  onClick={onClick}
                >
                  <i className="fa-regular fa-house-blank"></i>
                </NavLink>
              </li>
              {links.map((link, indx) => (
                <li key={link.id + indx} className="nav-item ff-barlow">
                  <NavLink
                    onClick={onClick}
                    to={link.path}
                    className={({ isActive }) =>
                      isActive
                        ? "text-yellow text-uppercase font-size-14 fw-medium"
                        : "text-white text-uppercase font-size-14 fw-medium"
                    }
                  >
                    {link.title}
                  </NavLink>
                </li>
              ))}
            </ul>
            <div className="position-relative">
              <Dropdown toggle={toggle} isOpen={dropdownOpen} direction="down">
                <DropdownToggle className="btn border-secondary-subtle bg-transparent rounded-pill border-2 dropdown-toggle-custom ">
                  {/* <i
                    className="uil-search mx-2 text-white bg-transparent font-size-16 "
                    style={{ height: "16px" }}
                  /> */}
                  <img
                    src={search}
                    height={23}
                    width={24}
                    color="#fffff"
                    className="mx-2  font-size-16 "
                  />
                </DropdownToggle>
                <div className="position-absolute">
                  <DropdownMenu>
                    <SearchDropDown onClose={handleCloseDropdown} />
                  </DropdownMenu>
                </div>
              </Dropdown>
            </div>
          </div>
        </Col>
        <Col className="d-none d-lg-block col-lg-2">
          <div className="text-end">
            {isAuth ? (
              <ProfileMenu titleColor="text-dark" />
            ) : (
              <Link
                to={"/login"}
                className="btn btn-primary-yellow font-size-14 ff-proxima text-uppercase d-flex justify-content-center align-items-center"
              >
                Join
                <span className="d-none d-xxl-inline-block font-size-14 ff-proxima text-uppercase">
                  &nbsp;Our
                </span>
                <span className="d-none d-md-inline-block font-size-14 ff-proxima text-uppercase">
                  &nbsp;Community
                </span>
              </Link>
            )}
          </div>
        </Col>
        <Col xs="8" className="d-lg-none d-block text-end">
          <TopNavOffCanvas />
        </Col>
      </Row>
    </Navbar>
  )
}

export default TopNavBar
