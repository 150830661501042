import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import CheckImg from "assets/images/check.svg"
import endpoints from "api/endpoints"
import { get } from "api/axios"
import { Link, useParams } from "react-router-dom"
import personPlaceholder from "assets/images/personPlaceholder.png"

export default function TheDockies() {
  const { id } = useParams()
  const [users, setUsers] = useState([])
  const [videoCounts, setVideoCounts] = useState({})
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [filter, setFilter] = useState({
    action: "lastMonth",
    text: "Last Month",
  })

  const toggle = () => setDropdownOpen(prevState => !prevState)

  // Fetch award-winning users
  const getFetch = async () => {
    try {
      const resp = await get(
        `${endpoints.getVideos}/winner/count?filter=${filter?.action}`
      )
      if (resp?.data?.success) {
        setUsers(resp?.data?.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  // Fetch videos count for each user
  const getVideoCountForUser = async userId => {
    try {
      const resp = await get(
        `${endpoints.getVideos}/my/${userId}?page=1&limit=5&filter=ACTIVE`
      )
      if (resp?.data?.success) {
        setVideoCounts(prevCounts => ({
          ...prevCounts,
          [userId]: resp?.data?.totalCount || resp?.data?.count || 0, // Store count in state
        }))
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getFetch()
  }, [filter])

  useEffect(() => {
    if (users.length > 0) {
      users.forEach(user => getVideoCountForUser(user._id))
    }
  }, [users])

  return (
    <section id="user-profile" className="">
      <Container fluid className="vh-75" style={{ minHeight: "60vh" }}>
        <h2 className="text-dark font-size-26 font-weight-semibold ff-barlow text-uppercase d-flex justify-content-between">
          The Dockie Awards
          <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"}>
            <DropdownToggle
              caret
              className="d-flex justify-content-between align-items-center filter-btn"
            >
              <span>{filter.text}</span>
              <img src={CheckImg} className="px-2" alt="Check Icon" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() =>
                  setFilter({ action: "lastMonth", text: "Last Month" })
                }
              >
                Last Month
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  setFilter({ action: "threeMonth", text: "Last Three Month" })
                }
              >
                Last Three Month
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  setFilter({ action: "lastYear", text: "Last Year" })
                }
              >
                Last Year
              </DropdownItem>
              <DropdownItem
                onClick={() => setFilter({ action: "active", text: "Older" })}
              >
                Older
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </h2>

        <Row className="col-sm-center pb-5 pb-sm-0">
          {users &&
            users.map(user => (
              <Col
                key={user._id}
                xs="6"
                sm="4"
                md="3"
                xl="2"
                className="my-2 gap-2"
              >
                <Link to={`/userprofile/${user?._id}`}>
                  <img
                    className="rounded-4 object-fit-cover dockie-class"
                    src={
                      user?.profileImage
                        ? process.env.REACT_APP_S3_BUCKET + user.profileImage
                        : personPlaceholder
                    }
                    alt="Header Avatar"
                  />
                  <h3 className="d-block text-uppercase fw-medium font-size-18 ff-barlow text-darkcolor pb-0 mb-0 pt-3">
                    {user.firstName} {user.lastName}
                  </h3>
                </Link>
                <span className="d-block ff-barlow fw-normal font-size-14 text-secondary pt-1">
                  {user.awardCount} awards win
                </span>
                <span className="d-block ff-barlow fw-normal font-size-14 text-secondary pt-1">
                  {videoCounts[user._id] || 0} videos posted{" "}
                  {/* Display video count */}
                </span>
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  )
}
