import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import touch from "assets/images/touch.png"
import award from "assets/images/award-01.png"
import upload from "assets/images/upload-square.png"
import gif from "assets/images/gif.png"

const VotingItem = ({ icon, label, date }) => (
  <Card
    className="mb-3 rounded-4"
    style={{
      backgroundColor: "#FFFFE9",
      height: "144px",
    }}
  >
    <CardBody className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center pb-2">
        <div
          className=" d-flex justify-content-center align-items-center rounded-4"
          style={{
            width: "120px",
            height: "120px",
            backgroundColor: "#D7F5E5",
            marginLeft: "-70px",
            marginTop: "-9px",
          }}
        >
          <img
            src={icon}
            alt="icon"
            style={{ width: "48px", height: "48px" }}
          />
        </div>

        <span className="gray-700 ps-4 ff-proxima font-size-24 fw-normal">
          {label}
        </span>
      </div>
      <span className="ff-proxima fw-bold font-size-24 text-black ">
        {date}
      </span>
    </CardBody>
  </Card>
)

const VotingSection = () => (
  <div className="py-5">
    <Row className="justify-content-center align-items-center mt-4">
      {/* Image Column */}
      <Col md={6} className="d-flex justify-content-center">
        <img src={gif} alt="Voting GIF" className="object-fit-cover" />
      </Col>

      {/* Voting Items Column */}
      <Col md={5}>
        <h2 className="text-start text-center ff-cooper fw-bold font-size-56">
          VOTING IS OPEN
        </h2>
        <VotingItem icon={touch} label="Submissions By" date="November 21" />
        <VotingItem icon={upload} label="Vote Until" date="November 21" />
        <VotingItem icon={award} label="Winner Announced" date="November 21" />
      </Col>
    </Row>
  </div>
)

export default VotingSection
