import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import play1 from "assets/images/home/play.svg"
import play from "assets/images/circle-bg.png"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import { Link } from "react-router-dom"
import ShuffleText from "components/animation/ShullfeText/ShullfeText"

const HowItkWorks = () => {
  const [blogs, setBlogs] = useState([])

  const contentHandler = async () => {
    const resp = await get(`${endpoints.blog}?page=1&limit=4&filter=featured`)
    if (resp?.data?.success) {
      setBlogs(resp?.data?.data)
    }
  }

  function getText(html) {
    var divContainer = document.createElement("div")
    divContainer.innerHTML = html
    return divContainer.textContent || divContainer.innerText || ""
  }

  useEffect(() => {
    contentHandler()
  }, [])

  const buttonLabels = [
    "SUBSCRIBE ON SOCIAL MEDIA",
    "CONNECT YOUR ACCOUNT",
    "SIGN IN TO THE DOCKIES",
    "I’M READY TO POST MY JOKES",
  ]

  return (
    <section id="how-dock-tok-work" className="py-2 bg-light">
      <Container fluid className="py-5  px-3 px-sm-2">
        <Row className="justify-content-center">
          <Col xs="12">
            <Row className="justify-content-center">
              <Col md={12}>
                <h2 className="fw-bold font-size-40 text-uppercase text-darkcolor text-center mb-2">
                  <ShuffleText text="HOW TO BECOME A DOCKIE" />
                </h2>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="pt-2 pb-2">
          {blogs.length > 0 ? (
            blogs.map((key, index) => (
              <Col
                lg={3}
                md={4}
                sm={6}
                xs={12}
                key={index}
                className="d-flex flex-column mb-4"
              >
                <Link
                  to={`/how-it-works-details/${key?._id}`}
                  className="d-flex flex-column align-items-center"
                >
                  <div className="position-relative w-100">
                    {key?.videoDetail?.thumbnail?.path ? (
                      <img
                        src={`${process.env.REACT_APP_S3_BUCKET}${key?.videoDetail?.thumbnail?.path}`}
                        alt="blog"
                        className="rounded-4 img-fluid w-100"
                        style={{ maxHeight: "500px", objectFit: "cover" }} // Ensures image scaling and no overflow
                      />
                    ) : (
                      <video className="rounded w-100 h-100"></video>
                    )}
                    {key?.videoDetail?.video && (
                      <button className="btn position-absolute top-50 start-50 translate-middle">
                        <div className="position-relative">
                          {/* Play Image */}
                          <img
                            src={play}
                            alt="Play"
                            height={100}
                            width={100}
                            className="position-absolute top-50 start-50 translate-middle"
                            style={{ zIndex: 1 }} // Higher z-index to ensure this appears above
                          />
                          {/* Play1 Image */}
                          <img
                            src={play1}
                            alt="Play1"
                            height={40}
                            width={40}
                            className="position-absolute top-50 start-50 translate-middle"
                            style={{ zIndex: 2 }} // Ensure play1 is above the other icon
                          />
                        </div>
                      </button>
                    )}
                  </div>

                  <div className="mt-3  w-100">
                    <h3 className="font-size-18 fw-medium text-uppercase text-darkcolor mb-2">
                      {getText(key.title).length > 25
                        ? getText(key.title).slice(0, 25) + "..."
                        : getText(key.title).slice(0, 25)}
                    </h3>
                    <p className="font-size-16 text-secondary">
                      {getText(key.content).length > 50
                        ? getText(key.content).slice(0, 50) + "..."
                        : getText(key.content).slice(0, 50)}
                    </p>
                    <button className="btn btn-primary-black-outline text-uppercase font-size-14">
                      {buttonLabels[index]}
                    </button>
                  </div>
                </Link>
              </Col>
            ))
          ) : (
            <h3 className="text-center">No Content Found!</h3>
          )}
        </Row>
      </Container>
    </section>
  )
}

export default HowItkWorks
