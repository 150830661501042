import React, { useState } from "react"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap"

const FaqItem = ({ question, answer, id, open, toggle }) => (
  <AccordionItem>
    <AccordionHeader targetId={id} className="bg-skin">
      <div className="d-flex justify-content-between align-items-center w-100">
        <h3 className="fw-medium font-size-18 text-darkcolor text-uppercase mb-0">
          {question}
        </h3>
        <button className="btn btn-link p-0 m-0 font-size-24 text-secondary"></button>
      </div>
    </AccordionHeader>
    <AccordionBody
      accordionId={id}
      className="mt-2 text-secondary font-size-16 fw-normal bg-skin"
    >
      {answer}
    </AccordionBody>
  </AccordionItem>
)

const Faq = () => {
  const [open, setOpen] = useState("")

  const faqs = [
    {
      id: "1",
      question: "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et.",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien.",
    },
    {
      id: "2",
      question: "Lorem ipsum dolor sit amet consectetur adipiscing elit ut et.",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit ut et massa elit. Aliquam in hendrerit urna. Pellentesque ac amet sapien.",
    },
    {
      id: "3",
      question: "Lorem ipsum dolor sit amet consectetur adipiscing elit ut et.",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit ut et massa elit. Aliquam in hendrerit urna. Pellentesque ac amet sapien.",
    },
    {
      id: "4",
      question: "Lorem ipsum dolor sit amet consectetur adipiscing elit ut et.",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit ut et massa elit. Aliquam in hendrerit urna. Pellentesque ac amet sapien.",
    },
  ]

  const toggle = id => {
    if (open === id) {
      setOpen("")
    } else {
      setOpen(id)
    }
  }

  return (
    <div>
      <h2 className="mb-4 fw-medium font-size-26 text-darkcolor text-uppercase">
        Frequently Asked Questions
      </h2>
      <Accordion open={open} toggle={toggle} className="bg-skin">
        {faqs.map(faq => (
          <FaqItem
            key={faq.id}
            id={faq.id}
            question={faq.question}
            answer={faq.answer}
            open={open}
            toggle={toggle}
          />
        ))}
      </Accordion>
    </div>
  )
}

export default Faq
