import React, { useEffect, useState } from "react"
import { Col, Row, Button } from "reactstrap"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import VideoCard from "components/Common/Cards/VideoCard"
import Placeholders from "../../Home/components/Placeholders"
import { Link } from "react-router-dom"

export default function VideosSection() {
  const [videoData, setVideoData] = useState([])

  // Fetch the videos once and limit to 4 items

  const [loadMore, setLoadMore] = useState({
    currentPage: 1,
    lastPage: 1,
  })
  const getFetch = async () => {
    try {
      const resp = await get(
        `${endpoints.getVideos}?page=1&filter=activeTopic&limit=4&status=active`
      )

      if (resp?.data?.success) {
        setVideoData(resp?.data?.data) // Set the video data
      }
    } catch (error) {
      console.error("Error fetching videos:", error)
    }
  }
  const handleLoadMore = () => {
    const nextPage = loadMore.currentPage + 1

    setLoadMore(prevState => ({
      ...prevState,
      currentPage: nextPage,
    }))

    getFetch(nextPage)
  }

  useEffect(() => {
    getFetch()
  }, [])

  return (
    <section
      id="how-dock-tok-work"
      className="py-2 px-0 px-lg-3"
      style={{ backgroundColor: "#FFFFE9" }}
    >
      <div className="px-2 px-lg-5">
        <p className="ff-cooper font-size-56 fw-normal text-black text-uppercase text-center p-0 m-0">
          video posted by dockies
        </p>
        <p
          className="ff-proxima fw-bold font-size-24 gray-700 text-center pb-2 "
          style={{ fontStyle: "italic" }}
        >
          Post, Watch, and Vote.
        </p>
        <Row className="justify-content-center">
          {videoData?.length > 0 ? (
            // Display 4 videos in one row
            videoData.map((card, index) => (
              <Col
                sm={6} // Takes up half of the row in smaller screens
                lg={3} // Takes up 1/4 of the row in larger screens (4 items in one row)
                className="mb-3"
                key={`Dockies-${index}`}
              >
                <VideoCard data={card} />
              </Col>
            ))
          ) : (
            // Show placeholder if no data
            <Col>
              <Placeholders />
            </Col>
          )}
        </Row>
        <div className="text-center mb-4">
          {" "}
          <Link
            to={"/login"}
            className="btn btn-primary-yellow font-size-14 font-weight-500 text-black bg-yellow text-uppercase text-center pt-3"
          >
            Join <span className="d-none d-xxl-inline-block">Our </span>
            <span className="d-none- d-md-inline-block">&nbsp;Community</span>
          </Link>{" "}
        </div>
      </div>
    </section>
  )
}
